import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {takeLatest, put, call} from 'redux-saga/effects';
import {
  fetchGenerateReportCreatorTypeName,
  fetchHqForGenerateReportCreatorTypeName,
  generateReportStateActions,
} from './slice';

/**
 * Employee Details Watcher
 */
export function* fetchGenerateReportDataWatcher() {
  yield takeLatest(
    fetchGenerateReportCreatorTypeName,
    fetchGenerateReportDataWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchGenerateReportDataWatcherWorker(action: any): any {
  try {
    yield put(generateReportStateActions.setGenerateReportLoading(true));
    const url = `${API_PATH.generateReport.getGenerateReport}`;
    const generateReportData = yield call(
      NetworkService.post,
      url,
      action?.payload,
      {},
    );
    if (
      generateReportData?.data?.message &&
      generateReportData?.status === HTTP_OK
    ) {
      ToasterService.showToaster(
        `${generateReportData?.data?.message}`,
        ToastType.ERROR,
      );
      yield put(generateReportStateActions.setGenerateReport([]));
      yield put(generateReportStateActions.setGenerateReportLoading(false));
    } else if (
      !generateReportData?.data?.message &&
      generateReportData?.status === HTTP_OK
    ) {
      yield put(
        generateReportStateActions.setGenerateReport(
          generateReportData?.data?.statusReportData,
        ),
      );
      yield put(
        generateReportStateActions.setGenerateReportSnaShot(
          generateReportData?.data?.statusReportData,
        ),
      );
      yield put(generateReportStateActions.setGenerateReportLoading(false));
    } else {
      ToasterService.showToaster(
        'Something went wrong while fetching generate report data!',
        ToastType.ERROR,
      );
      yield put(generateReportStateActions.setGenerateReportLoading(false));
      yield put(generateReportStateActions.setIsGenerateError(true));
    }
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching generate report data!',
      ToastType.ERROR,
    );
    yield put(generateReportStateActions.setGenerateReportLoading(false));
  }
}

/**
 * Generate Report HQ Watcher
 */
export function* fetchHQDropdownForGenerateReportWatcher() {
  yield takeLatest(
    fetchHqForGenerateReportCreatorTypeName,
    fetchHQDropdownForGenerateReportWatcherWorker,
  );
}
/**
 * Generate Report HQ Worker
 */
export function* fetchHQDropdownForGenerateReportWatcherWorker(): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.hqlistbydivisiondesignations}`;
    const HQDropdown = yield call(
      NetworkService.post,
      url,
      {divisionIds: [], designationIds: []},
      {},
    );

    yield put(
      generateReportStateActions.setGenerateReportHqDropDown(HQDropdown.data),
    );
  } catch (error) {}
}
