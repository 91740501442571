import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';
import {routeSelector} from '@app/router/redux';
import { billLibraryStateSelector } from '@app/screens/module-specific-utilities/pages/bill-library/redux/selectors';
import ArrowCircleLeftOutlinedIcon  from '@mui/icons-material/ArrowCircleLeftOutlined';
import { billLibraryStateActions } from '@app/screens/module-specific-utilities/pages/bill-library/redux';
import { useCallback, useEffect } from 'react';
import { appSelector } from '@app/store/selectors';
import Colors from '@app/themes/colors';
import { Button } from '@mui/material';
import { kebabCase } from 'lodash';
import { generateReportStateSelector } from '@app/screens/module-specific-utilities/pages/generateReport/redux/selectors';
import { appSliceActions } from '@app/store/reducers/appSlice';
import moment from 'moment';
import {bgColourFontBold, fitToColumnExcelJs} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseDetailedTabVIew';
import {saveAs} from 'file-saver';
import Excel from 'exceljs';
import {getMonthInitial} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseTable';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import theme from '@app/themes';
import { strings } from '@app/common/strings';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 12.7,
    minWidth:"0px",
  },
});
export const CommonNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const { isWriteAccess = false } = useSelector(
    appSelector.getUserInfo()
  );
  const generateReportGrid = useSelector(
    generateReportStateSelector.getGenerateReport(),
  );

  const excelInfo = useSelector(
    appSelector.getExcelData(),
  );
  const tabViewv= useSelector(
    billLibraryStateSelector.getGridVeiw()
  );
  const downloadExcel =()=> {
    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('Generate Report');
    let headers = ['DIVISION','STATE','SAP CODE','STAFF NAME','STAFF CODE','DESIGNATION NAME','HQ','EXP MONTH','KM','TA AMOUNT','DA AMOUNT','DA DAYS','PASSED ON','PASSED BY']
    ws.addRow(headers);
    const excelDataWithMonthInitials = excelInfo?.data?.map((obj: any) => ({
      divisionName: obj.divisionName,
      stateName:obj.stateName,
      staffSAPCode:obj.staffSAPCode,
      staffName:obj.staffName,
      staffCode:obj.staffCode,
      staffDesignationName:obj.staffDesignationName,
      hqName:obj.hqName,
      expenseMonth: getMonthInitial(obj.expenseMonth, monthMock()),
      totalDistance:obj.totalDistance,
      adminApprovedTAAmount:obj.adminApprovedTAAmount,
      adminApprovedDAAmount:obj.adminApprovedDAAmount,
      expenseDADays:obj.expenseDADays,
      passedOnDate:obj?.passedOnDate,
      passedByName:obj.passedByName
    }));
    excelDataWithMonthInitials?.forEach((rowData: any) => {
      ws.addRow(Object.values(rowData));
    });

    bgColourFontBold(ws);
    fitToColumnExcelJs(ws,excelDataWithMonthInitials);
    wb.xlsx
      .writeBuffer()
      .then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = `${kebabCase(navbarComponentHeading?.props?.children)}.xlsx`;
        saveAs(blob, fileName, {autoBom: false});
      })
      .catch(error => {
        console.error(error);
      });
  }
  const GridVeiw = useCallback(() => dispatch(
    billLibraryStateActions.setGridVeiw(false)),[]);
    useEffect(() => {
      if(generateReportGrid?.length > 0){
      dispatch(appSliceActions.setExcelData({
        data:generateReportGrid?.map((item:any)=>{
          return {...item,["passedOnDate"]:moment(item?.passedOnDate).format("DD/MM/YYYY")}}),
          
        headers: [
          { label: 'DIVISION', key: 'divisionName' },
          { label: 'STATE', key: 'stateName' },
          { label: 'SAP CODE', key: 'staffSAPCode' },
          { label: 'STAFF NAME', key: 'staffName' },
          { label: 'STAFF CODE', key: 'staffCode' },
          { label: 'DESIGNATION NAME', key: 'staffDesignationName' },
          { label: 'HQ', key: 'hqName' },
          { label: 'EXP MONTH', key: 'expenseMonth' },
          { label: 'KM', key: 'totalDistance' },
          { label: 'TA AMOUNT', key: 'adminApprovedTAAmount' },
          { label: 'DA AMOUNT', key: 'adminApprovedDAAmount' },
          { label: 'DA DAYS', key: 'expenseDADays' },
          { label: 'PASSED ON', key: 'passedOnDate' },
           { label: 'PASSED BY', key: 'passedByName' },
        ]
      
      }))
    }
    },[generateReportGrid]);
  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontWeight={theme.sizing.weight800}
          fontFamily={theme.fonts.poppins}
          fontStyle={theme.sizing.fontSize}
          display= {"flex"}
        >
         { tabViewv ?<ArrowCircleLeftOutlinedIcon style={{margin:"3px",fontSize:"24px"}}  onClick={GridVeiw}/>:''}
        {navbarComponentHeading}
        </Typography>
        { navbarComponentHeading?.props?.children === 'Generate Report' &&  <Button
            variant="text"
            className={classes.button}
            startIcon={<img
              alt="excel"
              width={20}
              src={require('../../../assets/images/MSExcel.png')}
            />}
            sx={{
              ...(!isWriteAccess && {color: 'grey'}),
            }}
            onClick={downloadExcel}
            style={{color: Colors.primary, textDecoration: 'none'}}>
            {strings.DownloadExcel}
          </Button>}
      </div>
    </div>
  );
};
