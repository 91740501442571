import {filterSelector} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useStyles} from '@app/screens/Styles/style';
import {appSliceActions} from '@app/store/reducers/appSlice';
import {Container} from '@mui/system';
import {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {GenerateReportGrid} from '@app/screens/module-specific-utilities/components/generate-report/generate-report-grid';
import {generateReportStateSelector} from '@app/screens/module-specific-utilities/pages/generateReport/redux/selectors';
import {
  fetchHqForGenerateReportCreator,
  generateReportStateActions,
} from './redux/slice';
import {fetchDesignationsDropdownCreator} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {fetchDivisionDataCreator} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {strings} from '@app/common/strings';
import {GenerateReportHeader} from '../../components/generate-report/generate-report-header';
import {Box, CircularProgress, Typography} from '@mui/material';

const CircularBar = () => {
  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <CircularProgress />
    </Box>
  );
};

export const GenerateReport = () => {
  const currentYear = new Date().getFullYear();
  const previousYears = Array.from(
    {length: 5},
    (_, index) => currentYear - index,
  ).map(year => ({label: year.toString(), value: year}));
  const months = [
    {label: 'January', value: 1},
    {label: 'February', value: 2},
    {label: 'March', value: 3},
    {label: 'April', value: 4},
    {label: 'May', value: 5},
    {label: 'June', value: 6},
    {label: 'July', value: 7},
    {label: 'August', value: 8},
    {label: 'September', value: 9},
    {label: 'October', value: 10},
    {label: 'November', value: 11},
    {label: 'December', value: 12},
  ];
  const dispatch = useDispatch();
  const classes = useStyles();
  const filters: any = useSelector(
    filterSelector.getFilterState(),
    shallowEqual,
  );
  const generateReportGrid = useSelector(
    generateReportStateSelector.getGenerateReport(),
  );
  const generateReportLoading = useSelector(
    generateReportStateSelector.getGenerateReportLoading(),
  );

  useEffect(() => {
    dispatch(generateReportStateActions.setYearDropdown(previousYears));
    dispatch(generateReportStateActions.setMonthDropdown(months));
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.commonheader,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>
          {strings.ModuleSpecificUtilitie.generatepage.generateReport}
        </div>,
      ),
    );
    dispatch(generateReportStateActions.setIsGenerateReport(true));
    dispatch(fetchDivisionDataCreator());
    dispatch(fetchDesignationsDropdownCreator());
    dispatch(fetchHqForGenerateReportCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(generateReportStateActions.setYearDropdown([]));
      dispatch(generateReportStateActions.setMonthDropdown([]));
      dispatch(generateReportStateActions.setIsGenerateReport(false));
      dispatch(generateReportStateActions.setGenerateReportPageNo(0));
      dispatch(generateReportStateActions.setGenerateReport([])); 
      dispatch(generateReportStateActions.setGenerateReportLoading(null));    
      dispatch(
        appSliceActions.setExcelData({
          data: [],
          headers: [],
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const renderGenerateContent = () => {
    if (generateReportLoading === false && !generateReportGrid?.length) {
      return (
        <Box className={classes?.mt}>
          <Typography>{strings.noRecordFound}</Typography>
        </Box>
      );
    }
    if (generateReportLoading) {
      return <CircularBar />;
    }
    return generateReportGrid?.length > 0 ? (
      <GenerateReportGrid generateReportgridData={generateReportGrid} />
    ) : null;
  };

  return (
    <div>
      <Container maxWidth={false}>
        <GenerateReportHeader generateReportgridData={generateReportGrid} />
        {renderGenerateContent()}
      </Container>
    </div>
  );
};
