import {
  applyFilterWatcher,
  applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import {fetchRoutesDataWatcher} from '@app/router/redux/routeHandlerSaga';
import {
  accessGroupMenuItemsWatcher,
  addAccessGroupWatcher,
  disableAccessGroupWatcher,
  editAccessGroupWatcher,
  fetchAccessGroupMasterWatcher,
  fetchAccessGroupsAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/access-group-master/redux/saga';
import {
  fetchInventoryCnfReturnWatcher,
  fetchCNFStatesAndDivisionsWatcher,
  fetchCNFHeadquartersWatcher,
  fetchCnfAutoSuggestionWatcher,
  fetchFilteredDataWatcher,
  fetchCnfReturnItemsFilterWatcher,
  fetchApprovedItemsWatcher,
  fetchRejectItemsWatcher,
  fetchCnfAuditDivisionWatcher,
  fetchCnfReturnAudtilDetialsWatcher,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/saga';
import {
  fetchInventoryIntransistChallansWatcher,
  fetchAutoSuggestionWatcher,
  fetchInTransitFilteredDataWatcher,
  postIntransitChallanOpenCloseWatcher,
  enableDisableIntransitChallansWatcher,
  fetchVoucherNumberDataWatcher,
  fetchRecievedChallansDataWatcher,
  fetchIntransitChallanDivivionsWatcher,
  fetchChallanAuditDataWatcher,
  fetchChallanAuditReportWatcher,
  fetchChallanReceivesearchDataWatcher,
  fetchChallanReceiveAutoSuggestWatcher,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/saga';

import {
  fetchInventoryApproveReturnWatcher,
  fetchApproveStatesAndDivisionsWatcher,
  fetchApproveHeadquartersWatcher,
  fetchApproveReturnItemsAutoSuggestionWatcher,
  fetchApproveFilteredDataWatcher,
  fetchApproveReturnItemsFilterWatcher,
  fetchApprovedReturnItemsWatcher,
  fetchApproveRejectItemsWatcher,
} from '@app/screens/inventory-management/pages/ApprovedReturnedItems/redux/saga';
import {
  editAdminUserWatcher,
  fetchAccessGroupDropDownWatcher,
  fetchAdminUsersDataWatcher,
  fetchDivisionDropdownWatcher,
  fetchStateDropdownWatcher,
  addAdminUserWatcher,
  disableAdminUserWatcher,
  fetchAdminUsersAutoSuggestDataWatcher,
  fetchAdminAddUsersAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/user-management/redux/saga';

import {
  fetchPlanAndMeetStatusListWatcher,
  updateMTPStatusWatcher,
  BulkGSPUpdateWatcher,
  bulkSTPUpdateWatcher,
  bulkMTPUpdateWatcher,
  bulkDCRPUpdateWatcher,
  fetchAllHierarchyWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';

import {
  fetchStateDataWatcher,
  fetchDivisionDataWatcher,
  fetchManagerDataWatcher,
  fetchStatusDataWatcher,
  fetchStaffDataWatcher,
  fetchExpenseApprovalsWatcher,
  fetchDailyExpenseApprovalsWatcher,
  fetchMiscExpenseApprovalsWatcher,
  fetchConfirmExpenseApprovalsWatcher,
  fetchRejectExpenseApprovalsWatcher,
  fetchDailyExpenseApprovalsViewBillWatcher,
  uploadMiscExpenseDataWatcher,
  fetchMiscExpenseApprovalsViewBillWatcher,
  fetchDailyExpenseApprovalsDcrReportWatcher,
  fetchDailyExpenseDcrMapUrlDataWatcher,
  fetchMiscExpenseFileDeleteDataWatcher,
  fetchDailyExpensePopupReadOnlyDataWatcher,
  fetchDailyExpensePopupTADADataWatcher,
  fetchDailyExpensePopupTADASaveDataWatcher,
  fetchMiscSaveWatcher,
  deductDaAmountWatcher,
  approveExpenseStatusWatcher,
  fetchDailyExpenseExceptionSummaryWatcher,
  fetchMonthlyExpenseWatcher,
  footerDataWatcher,
  fetchInvoiceDetialsWatcher,
  fetchBillStatusWatcher,
  fetchBillDetailsWatcher,
  supportingDocWatcher,
  deleteBillWatcher,
  fetchDailyForAllExpenseApprovalsWatcher,
  fetchMonthlyAllExpenseWatcher,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/saga';
import {
  fetchExpenseTypeDataWatcher,
  fetchExpenseCategoryWatcher,
  fetchactiveInactiveExpenseTypeWatcher,
  fetchExpenseTypeAutoSuggestDataWatcher,
  createExpenseTyperWatcher,
  editExpenseTypeWatcher,
  fetchExpenseTypeOptionsWatcher,
  fetchAutoSuggestHqDataWatcher,
} from '@app/screens/component-management/components/redux/saga';
import {
  fetchExpenseTAMasterGridDataWatcher,
  fetchExpenseTAMasterInAutoSuggestDataWatcher,
  activeInactiveExpenseTAMasterWatcher,
  addAddExpenseTAMasterWatcher,
  editExpenseTAMastereWatcher,
  AddExpenseValidationTAMasterWatcher,
} from '@app/screens/master-data-management/pages/expense-ta-master/redux/saga';
import {
  fetchExpenseDAMasterGridDataWatcher,
  addExpenseDAMasterWatcher,
  fetchDesignationsDropdownWatcher,
  fetchHQDropdownWatcher,
  activeInactiveExpenseDAMasterWatcher,
  editExpenseDAMastereWatcher,
  fetchExpenseDAMasterInAutoSuggestDataWatcher,
  fetchExpenseDAMasterDesignationByDivisionWatcher,
  fetchHQTypeDropdownWatcher,
  fetchHqByEdividionDesginationDAMasterWatcher,
  AddExpenseValidationDAMasterWatcher,
  fetchExportFileCreatorWatcher,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/saga';

import {all, call, spawn} from 'redux-saga/effects';
import {
  fetchExpenseTypeDropdownConfigDataWatcher,
  fetchExpenseTypeSettingGridDataWatcher,
  fetchactiveInactiveExpenseTypeSettingWatcher,
  createExpenseTypeSettingrWatcher,
  editExpenseTypeSettingeWatcher,
  ExpenseDateWatcher,
  saveExpenseTypeDateGridWatcher,
  fetchHqByEdividionDesginationxpenseTypeSettingWatcher,
  createExpenseTypeSettingValidationWatcher,
  createProRataMiscExpenseWatcher,
  EditProRataMiscExpense,
} from '@app/screens/configuration-management/components/redux/saga';
import {
  fetchCannotAccessModalUsersWatcher,
  fetchNotificationsWatcher,
  fetchUserInfoWatcher,
  markNotificationReadWatcher,
  fetchUserAppSettingsWatcher,
} from '@app/store/sagas/appSaga';
import {
  fetchHqsWatcher,
  fetchMTPRecommendationsWatcher,
  filterBasedDownloadExcelWatcher,
  fetchPreferredFiltersWatcher,
  fetchMTPUnlockReasonsWatcher,
  enableDCRWatcher,
  fetchPlanAndMeetHierarchyWatcher,
  HierarchyBasedDownloadExcelWatcher,
  webAccessUnlockWatcher,
  getWebAccessStatusWatcher,
  enableUnfilledDCRWatcher,
  bulkSTPUnlockWatcher,
  bulkMTPUnlockWatcher,
  enableRealmUploadWatcher,
  downloadLockedDcrExcelWatcher,
  dcrLockedStatusWatcher
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
  fetchDcrUnlockRequestListWatcher,
  fetchDCRUnlockReasonsWatcher,
  approveDCRUnlockRequestWatcher,
  fetchDCRHqsWatcher,
  dcrDownloadExcelWatcher,
  dcrBulkUnlockWatcher,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import {
  fetchPartiesBySpidWatcher,
  fetchPartyDetailBySpidWatcher,
  fetchCloningPeriodWatcher,
  transferPositionUpdateWatcher,
  fetchPositionCloningListWatcher,
  clonePositionUpdateWatcher,
  fetchPositionTransferListWatcher,
  validatePartyTransferWatcher,
  areaAllocationWatcher,
  fetchAreasBySpidWatcher,
} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {
  fetchApprovalWorkflowListWatcher,
  fetchMasterConfigDataWatcher,
  createApprovalResponseWatcher,
} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import {updateMinApproverConfigWatcher} from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import {modifyDCRRequestWatcher} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';

import {
  fetchInventoryConfiguratorCustomerTypeWatcher,
  fetchInventoryConfiguratorDivisionWatcher,
  fetchInventoryConfiguratorDoctorQualificationWatcher,
  fetchInventoryConfiguratorDoctorSpecialityWatcher,
  fetchInventoryConfiguratorRegionHeirarcyWatcher,
  fetchInventoryConfiguratorSpecCategoryWatcher,
  fetchInventoryConfiguratorClassCodeWatcher,
  fetchInventoryConfiguratorRolesWatcher,
  fetchInventoryConfiguratorItemCategoryWatcher,
  fetchInventoryConfiguratorItemCodeWatcher,
  fetchInventoryConfiguratorSetValueWatcher,
  fetchInventoryConfiguratorQualificationWatcher,
} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux/saga';
import {
  fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
  fetchExpectedUtilizatonDaysWatcher,
  fetchNewExpectedUtilizatonDaysWatcher,
} from '@app/screens/inventory-management/pages/Expected Utilizaton Date/redux/saga';
import {
  fetchItemCategoryWatcher,
  fetchInventoryDeleteRolesWatcher,
  fetchInventorySaveRolesWatcher,
  fetchInventoryDivisionWatcher,
  fetchInventoryRolesPage2Watcher,
  fetchInventorySaveRolesPage2Watcher,
  fetchInventoryDeleteRolesPage2Watcher,
  fetchInventoryPartyTypeWatcher,
  fetchItemCategoryUserWatcher,
  fetchInventoryRulesActiveInActiveWatcher,
  fetchInventoryRulesActiveWatcher,
  fetchInventoryRulesInActiveWatcher,
} from '@app/screens/inventory-management/pages/inventoryRules/redux/saga';
import {
  fetchInTransitAccessDivisionStateWatcher,
  fetchInTransitAccessUserWatcher,
  fetchInTransitAccessDivisionLockedWatcher,
  fetchInTransitAccessStateLockedWatcher,
  fetchInTransitAccessUserLockedWatcher,
  fetchInTransitAccessUserStateDivisionRoleWatcher,
} from '@app/screens/inventory-management/pages/in-transit-access/redux/saga';
import {
  fetchStateAndDivisionWatcher,
  fetchHeadquaterWatcher,
  fetchPendencyReportWatcher,
  fetchDivisioinStateDataWatcher,
  fetchItemSampleAutoSuggestWatcher,
  fetchPendencySubordinatesWatcher,
  fetchUserListDataWatcher,
  fetchItemAndSampleWatcher,
  fetchExpectedUtilizationDateWatcher,
} from '@app/screens/inventory-management/pages/PendencyReport/redux/saga';
import {
  unmappedActivityListWatcher,
  mappedActivityListWatcher,
  saveActivityMappingWatcher,
  fetchDivisionMappingWatcher,
  fetchActivitiesWatcher,
  saveActivitiesWatcher,
} from '@app/screens/configuration-management/pages/activity-mapping/redux';
import {
  fetchChallanShortQtyApprovalDataListWatcher,
  fetchVoucherDetailsListWatcher,
  fetchChallanShortQtyApprovalWatcher,
  fetchChallanShortQtyRejectWatcher,
  FetchAutoSuggestionChallanWatcher,
  fetchChallanShortQuantityAuditDivivionsWatcher,
  fetchAutoSuggestionChallanShortQtyWatcher,
  fetchChallanShortQuantityAuditTrailDataWatcher,
  fetchChallanAuditTrailExcelSheetWatcher,
} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/saga';
import {fetchGspScheduleListWatcher} from '@app/screens/configuration-management/pages/gsp-schedule/redux';
import {
  fetchGetExpenseStatusActionsWatcher,
  fetchStatusManagerAutoSuggestStaffWatcher,
  saveExpenseStatusActionsWatcher,
} from '@app/screens/module-specific-utilities/pages/status-manager/redux/saga';
import {
  fetchDesignationByDivisionWatcher,
  fetchEmployeebydivisionWatcher,
  fetchHqByDivisionsandDesignationsWatcher,
  fetchPoolByDivisionsandDesignationsWatcher,
  fetchCategoryWatcher,
  fetchStatusWatcher,
  fetchDivisionWatcher,
  fetchBillDownloadWatcher,
  fetchViewHistoryWatcher,
  reviewSaveWatcher,
  fetchEmployeebydivisionforDropdownWatcher,
  reviewSearchWatcher,
} from '@app/screens/module-specific-utilities/pages/bill-library/redux/saga';
import {
  fetchExpenseParameterDataWatcher,
  fetchDownloadWatcher,
  fileUploadrDataWatcher,
  fetchExpenseFileNumberWatcher,
  downloadExpenseFileNumberTempWatcher,
} from '@app/screens/module-specific-utilities/pages/expense/redux/saga';
import {
  fetchExpenseReportsDataWatcher,
  fetchExpenseReportPagesWatcher,
  fetchExpenseReportsYearlyDataWatcher,
  fetchExpenseBifurcateDataWatcher,
} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/saga';
import {
  fetchGenerateReportDataWatcher,
  fetchHQDropdownForGenerateReportWatcher,
} from '@app/screens/module-specific-utilities/pages/generateReport/redux/saga';
import {
  fetchGspDivisionsWatcher,
  saveConfigurationWatcher,
  validatePrerequisitesWatcher,
} from '@app/screens/configuration-management/pages/gsp-schedule/redux/saga';
import {
  createDivisionWatcher,
  fetchDivisionsDataWatcher,
} from '@app/screens/configuration-management/pages/onboard-new-division/redux';

import {
  fetchAutoSuggestListForItemsWatcher,
  fetchAutoSuggestListForStaffNameWatcher,
  fetchLedgerReportDataWatcher,
} from '@app/screens/inventory-management/pages/ledgerReport/redux/saga';
import {
  fetchDataCorrectionListWatcher,
  updateDataCorrectionWatcher,
} from '@app/screens/master-data-management/pages/data-correction/redux';

import {
  fetchAllowedHolidayByCountryYearWatcher,
  fetchCountryWatcher,
  fetchHolidatByYearWatcher,
  fetchHolidayDivisionWatcher,
  fetchStateeWatcher,
} from '@app/screens/holiday-management/redux/saga';
import {
  fetchGetAllBalanceLeaveApiWatcher,
  fetchLeaveHistoryWatcher,
  fetchReportersApiWatcher,
  fetchUserRoleWatcher,
  fetchLeaveTypeWatcher,
} from '@app/screens/leave-management/redux';
import {
  deactivateInventoryRuleWatcher,
  fetchInventoryListWatcher,
  fetchInventoryMasterDataWatcher,
  getInventoryRuleWatcher,
  saveInventoryRuleWatcher,
  downloadInventoryRuleWatcher,
  downloadSampleBulkUploadWatcher,
  bulkUploadInventoryRuleWatcher,
} from '@app/screens/configuration-management/pages/inventory-compliance/redux';

import {
  directoryLoadingHandlerWatcher,
  directoryNavActionHandlerWatcher,
  directoryNavSearchQueryWatcher,
  directoryNavToggleWatcher,
  directoryUpdateCurrentPageWatcher,
  updateDirectoryNavWatcher,
} from '@app/screens/directory-configuration/redux';
import {
  fetchDivisionsWatcher,
  fetchPartyGroupsWatcher,
  updateTabsWatcher,
} from '@app/screens/directory-configuration/pages/tab-management/redux';
import {
  fetchHoUserCategoryWatcher,
  fetchHoUserDepartmentWatcher,
  fetchHoUserDivisionWatcher,
  fetchHoUserListWatcher,
  fetchHoUserRegionWatcher,
  fetchHoUserStateWatcher,
} from '@app/screens/directory-configuration/pages/ho-user/redux';
import {
  fetchMappingFieldsWatcher,
  fetchTabListWatcher,
  updateColumnsWatcher,
} from '@app/screens/directory-configuration/pages/column-management/redux';
import {
  getSocialMediaCategoryWatcher,
  updateSocialMediaListWatcher,
} from '@app/screens/directory-configuration/pages/social media/redux';
import {fetchDepartmentListWatcher} from '@app/screens/directory-configuration/pages/department-management/redux';
import {fetchHoContactCategoryWatcher} from '@app/screens/directory-configuration/pages/ho-contact-category/redux';
import {fetchSocialMediaCategoryListWatcher} from '@app/screens/directory-configuration/pages/social-media-category/redux/saga';

export function* rootSaga() {
  /**
   *
   *  Register watchers
   *
   */
  const sagas: any = [
    fetchRoutesDataWatcher,
    fetchUserInfoWatcher,
    fetchAdminUsersDataWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    editAdminUserWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
    fetchCannotAccessModalUsersWatcher,
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
    editAccessGroupWatcher,
    disableAccessGroupWatcher,
    addAccessGroupWatcher,
    accessGroupMenuItemsWatcher,
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    fetchNotificationsWatcher,
    fetchMTPRecommendationsWatcher,
    fetchHqsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    markNotificationReadWatcher,
    enableDCRWatcher,
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchUserAppSettingsWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
    fetchPartiesBySpidWatcher,
    fetchAreasBySpidWatcher,
    fetchPartyDetailBySpidWatcher,
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
    BulkGSPUpdateWatcher,
    updateMinApproverConfigWatcher,
    fetchInventoryCnfReturnWatcher,
    fetchInventoryIntransistChallansWatcher,
    fetchAutoSuggestionWatcher,
    fetchInTransitFilteredDataWatcher,
    postIntransitChallanOpenCloseWatcher,
    enableDisableIntransitChallansWatcher,
    fetchVoucherNumberDataWatcher,
    fetchCNFStatesAndDivisionsWatcher,
    fetchCNFHeadquartersWatcher,
    fetchCnfAutoSuggestionWatcher,
    fetchFilteredDataWatcher,
    fetchCnfReturnItemsFilterWatcher,
    fetchApprovedItemsWatcher,
    fetchRecievedChallansDataWatcher,
    fetchIntransitChallanDivivionsWatcher,
    fetchRejectItemsWatcher,
    fetchApproveReturnItemsAutoSuggestionWatcher,
    fetchInventoryConfiguratorDivisionWatcher,
    fetchInventoryConfiguratorCustomerTypeWatcher,
    fetchInventoryConfiguratorRegionHeirarcyWatcher,
    fetchInventoryConfiguratorDoctorSpecialityWatcher,
    fetchInventoryConfiguratorDoctorQualificationWatcher,
    fetchInventoryConfiguratorSpecCategoryWatcher,
    fetchInventoryConfiguratorClassCodeWatcher,
    fetchInventoryConfiguratorRolesWatcher,
    fetchInventoryConfiguratorItemCategoryWatcher,
    fetchInventoryConfiguratorItemCodeWatcher,
    fetchInventoryConfiguratorSetValueWatcher,
    fetchInventoryConfiguratorQualificationWatcher,
    modifyDCRRequestWatcher,
    fetchExpenseTypeDataWatcher,
    fetchExpenseCategoryWatcher,
    fetchExpenseTypeDropdownConfigDataWatcher,
    fetchDesignationsDropdownWatcher,
    fetchHQDropdownWatcher,
    fetchExpenseTAMasterGridDataWatcher,
    fetchExpenseTAMasterInAutoSuggestDataWatcher,
    activeInactiveExpenseTAMasterWatcher,
    addAddExpenseTAMasterWatcher,
    fetchactiveInactiveExpenseTypeWatcher,
    fetchExpenseTypeAutoSuggestDataWatcher,
    createExpenseTyperWatcher,
    editExpenseTypeWatcher,
    editExpenseTAMastereWatcher,
    fetchExpenseTypeSettingGridDataWatcher,
    fetchactiveInactiveExpenseTypeSettingWatcher,
    createExpenseTypeSettingrWatcher,
    editExpenseTypeSettingeWatcher,
    fetchExpenseDAMasterGridDataWatcher,
    activeInactiveExpenseDAMasterWatcher,
    addExpenseDAMasterWatcher,
    editExpenseDAMastereWatcher,
    fetchDivisionWatcher,
    fetchExpenseDAMasterInAutoSuggestDataWatcher,
    fetchGenerateReportDataWatcher,
    fetchStatusManagerAutoSuggestStaffWatcher,
    fetchGetExpenseStatusActionsWatcher,
    saveExpenseStatusActionsWatcher,
    fetchStateDataWatcher,
    fetchDivisionDataWatcher,
    fetchManagerDataWatcher,
    fetchStaffDataWatcher,
    fetchExpenseApprovalsWatcher,
    fetchStatusDataWatcher,
    fetchDailyExpenseApprovalsWatcher,
    fetchMiscExpenseApprovalsWatcher,
    fetchConfirmExpenseApprovalsWatcher,
    fetchRejectExpenseApprovalsWatcher,
    fetchDesignationByDivisionWatcher,
    fetchEmployeebydivisionWatcher,
    fetchHqByDivisionsandDesignationsWatcher,
    fetchPoolByDivisionsandDesignationsWatcher,
    fetchExpenseParameterDataWatcher,
    fetchDownloadWatcher,
    fetchCategoryWatcher,
    fetchStatusWatcher,
    ExpenseDateWatcher,
    fileUploadrDataWatcher,
    saveExpenseTypeDateGridWatcher,
    fetchExpenseTypeOptionsWatcher,
    fetchHQTypeDropdownWatcher,
    fetchExpenseDAMasterDesignationByDivisionWatcher,
    fetchBillDownloadWatcher,
    fetchViewHistoryWatcher,
    fetchHqByEdividionDesginationxpenseTypeSettingWatcher,
    fetchDailyExpenseApprovalsViewBillWatcher,
    uploadMiscExpenseDataWatcher,
    fetchAutoSuggestHqDataWatcher,
    fetchMiscExpenseApprovalsViewBillWatcher,
    fetchHqByEdividionDesginationDAMasterWatcher,
    fetchDailyExpenseApprovalsDcrReportWatcher,
    fetchDailyExpenseDcrMapUrlDataWatcher,
    AddExpenseValidationDAMasterWatcher,
    AddExpenseValidationTAMasterWatcher,
    createExpenseTypeSettingValidationWatcher,
    reviewSaveWatcher,
    fetchMiscExpenseFileDeleteDataWatcher,
    webAccessUnlockWatcher,
    getWebAccessStatusWatcher,
    fetchPositionTransferListWatcher,
    validatePartyTransferWatcher,
    fetchInventoryApproveReturnWatcher,
    fetchApproveStatesAndDivisionsWatcher,
    fetchApproveHeadquartersWatcher,
    fetchApproveFilteredDataWatcher,
    fetchApproveReturnItemsFilterWatcher,
    fetchApprovedReturnItemsWatcher,
    fetchApproveRejectItemsWatcher,
    fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
    fetchExpectedUtilizatonDaysWatcher,
    fetchNewExpectedUtilizatonDaysWatcher,
    fetchItemCategoryWatcher,
    fetchInventoryDeleteRolesWatcher,
    fetchInventorySaveRolesWatcher,
    fetchInventoryDivisionWatcher,
    fetchInventoryRolesPage2Watcher,
    fetchInventorySaveRolesPage2Watcher,
    fetchInventoryDeleteRolesPage2Watcher,
    fetchInventoryPartyTypeWatcher,
    validatePartyTransferWatcher,
    fetchInTransitAccessDivisionStateWatcher,
    fetchInTransitAccessUserWatcher,
    fetchInTransitAccessDivisionLockedWatcher,
    fetchInTransitAccessStateLockedWatcher,
    fetchInTransitAccessUserLockedWatcher,
    fetchInTransitAccessUserStateDivisionRoleWatcher,
    fetchChallanShortQtyApprovalDataListWatcher,
    validatePartyTransferWatcher,
    areaAllocationWatcher,
    unmappedActivityListWatcher,
    mappedActivityListWatcher,
    saveActivityMappingWatcher,
    fetchActivitiesWatcher,
    fetchDivisionMappingWatcher,
    fetchVoucherDetailsListWatcher,
    fetchChallanShortQtyApprovalWatcher,
    fetchChallanShortQtyRejectWatcher,
    FetchAutoSuggestionChallanWatcher,
    fetchChallanShortQuantityAuditDivivionsWatcher,
    fetchAutoSuggestionChallanShortQtyWatcher,
    fetchChallanShortQuantityAuditTrailDataWatcher,
    fetchChallanAuditTrailExcelSheetWatcher,
    fetchItemCategoryUserWatcher,
    fetchInventoryRulesActiveInActiveWatcher,
    fetchInventoryRulesActiveWatcher,
    fetchInventoryRulesInActiveWatcher,
    fetchCnfAuditDivisionWatcher,
    fetchCnfReturnAudtilDetialsWatcher,
    fetchChallanAuditDataWatcher,
    fetchChallanAuditReportWatcher,
    fetchChallanReceivesearchDataWatcher,
    saveActivitiesWatcher,
    fetchChallanReceiveAutoSuggestWatcher,
    fetchStateAndDivisionWatcher,
    fetchHeadquaterWatcher,
    fetchPendencyReportWatcher,
    fetchDivisioinStateDataWatcher,
    fetchItemSampleAutoSuggestWatcher,
    fetchPendencySubordinatesWatcher,
    fetchUserListDataWatcher,
    fetchAutoSuggestListForStaffNameWatcher,
    fetchItemAndSampleWatcher,
    fetchExpectedUtilizationDateWatcher,
    fetchAutoSuggestListForItemsWatcher,
    fetchLedgerReportDataWatcher,
    enableUnfilledDCRWatcher,
    dcrBulkUnlockWatcher,
    bulkSTPUpdateWatcher,
    bulkMTPUpdateWatcher,
    bulkDCRPUpdateWatcher,
    fetchAllHierarchyWatcher,
    fetchGspScheduleListWatcher,
    validatePrerequisitesWatcher,
    saveConfigurationWatcher,
    fetchExpenseFileNumberWatcher,
    fetchMonthlyExpenseWatcher,
    footerDataWatcher,
    fetchExpenseReportsDataWatcher,
    fetchExpenseReportPagesWatcher,
    createProRataMiscExpenseWatcher,
    fetchExpenseReportsYearlyDataWatcher,
    fetchExpenseBifurcateDataWatcher,
    fetchInvoiceDetialsWatcher,
    fetchBillStatusWatcher,
    fetchBillDetailsWatcher,
    supportingDocWatcher,
    deleteBillWatcher,
    EditProRataMiscExpense,
    downloadExpenseFileNumberTempWatcher,
    fetchDailyForAllExpenseApprovalsWatcher,
    fetchMonthlyAllExpenseWatcher,
    reviewSearchWatcher,
    fetchGspDivisionsWatcher,
    bulkSTPUnlockWatcher,
    bulkMTPUnlockWatcher,
    fetchDataCorrectionListWatcher,
    updateDataCorrectionWatcher,
    fetchExportFileCreatorWatcher,
    fetchDailyExpensePopupReadOnlyDataWatcher,
    fetchDailyExpensePopupTADADataWatcher,
    fetchDailyExpensePopupTADASaveDataWatcher,
    fetchMiscSaveWatcher,
    deductDaAmountWatcher,
    approveExpenseStatusWatcher,
    fetchDailyExpenseExceptionSummaryWatcher,
    fetchEmployeebydivisionforDropdownWatcher,
    fetchCountryWatcher,
    fetchStateeWatcher,
    fetchHolidatByYearWatcher,
    fetchHolidayDivisionWatcher,
    fetchAllowedHolidayByCountryYearWatcher,
    fetchReportersApiWatcher,
    fetchGetAllBalanceLeaveApiWatcher,
    fetchLeaveHistoryWatcher,
    updateDirectoryNavWatcher,
    updateTabsWatcher,
    directoryNavActionHandlerWatcher,
    fetchDivisionsWatcher,
    fetchPartyGroupsWatcher,
    directoryLoadingHandlerWatcher,
    updateColumnsWatcher,
    fetchTabListWatcher,
    fetchMappingFieldsWatcher,
    fetchHoContactCategoryWatcher,
    directoryNavSearchQueryWatcher,
    fetchHoUserCategoryWatcher,
    fetchHoUserDepartmentWatcher,
    fetchHoUserDivisionWatcher,
    fetchHoUserStateWatcher,
    fetchHoUserRegionWatcher,
    fetchHoUserListWatcher,
    fetchDepartmentListWatcher,
    directoryNavToggleWatcher,
    updateSocialMediaListWatcher,
    getSocialMediaCategoryWatcher,
    fetchSocialMediaCategoryListWatcher,
    directoryUpdateCurrentPageWatcher,
    fetchDivisionsDataWatcher,
    createDivisionWatcher,
    enableRealmUploadWatcher,
    dcrLockedStatusWatcher,
    fetchInventoryListWatcher,
    getInventoryRuleWatcher,
    saveInventoryRuleWatcher,
    deactivateInventoryRuleWatcher,
    fetchInventoryMasterDataWatcher,
    downloadInventoryRuleWatcher,
    downloadSampleBulkUploadWatcher,
    bulkUploadInventoryRuleWatcher,
    fetchUserRoleWatcher,
    fetchLeaveTypeWatcher,
    downloadLockedDcrExcelWatcher,
    fetchHQDropdownForGenerateReportWatcher,
  ];

  /**
   * keep everything (e.g., child tasks) alive
   *
   **/
  yield all(
    sagas.map((saga: any) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {}
        }
      }),
    ),
  );
}
